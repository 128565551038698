/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { doesEmailExist, doesPharmacyExist, resetError } from '../actions';
import { setCustomError } from '../actions/errorActions';
import httpClient from '../controllers/httpClient';
import { isPendingAction, isRejectedAction } from './statusReducer';
import { errorType } from '../helpers/Constants';
import LogsHandler from '../helpers/LogsHandler';

export interface IGeneralError {
  [key: string]: string;
}

export type IErrorState = IGeneralError & {
  latestError: null | string;
  errorType: null | number;
};

const initialState: IErrorState = {
  latestError: '',
  errorType: null,
};

const customErrors = {
  en: {
    networkError: 'Network Error',
    nameAlreadyExists: 'The name that you have entered, already exist!',
    emailAlreadyExists: 'The email that you have entered, already exist!',
  },
  fr: {
    networkError: 'Erreur Réseau',
    nameAlreadyExists: 'Le nom que vous avez entré,déjà existant!',
    emailAlreadyExists: 'Le email que vous avez entré,déjà existant!',
  },
};

const errorReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetError, () => {
      return initialState;
    })
    .addCase(setCustomError, (state, action) => {
      state.latestError = action?.payload?.message;
    })
    .addCase(doesPharmacyExist.fulfilled, (state, action) => {
      if (action.payload) {
        state.latestError =
          customErrors[
            httpClient.defaults.headers.common[
              'locale'
            ] as keyof typeof customErrors
          ].nameAlreadyExists;
        state.errorType = errorType.error;
      }
    })
    .addCase(doesEmailExist.fulfilled, (state, action) => {
      // CUSTOM ERRORS FOR WEB & MOBILE
      if (action.payload) {
        state.latestError =
          customErrors[
            httpClient.defaults.headers.common[
              'locale'
            ] as keyof typeof customErrors
          ].emailAlreadyExists;
        state.errorType = errorType.error;
      }
    })
    .addMatcher(isPendingAction, (state, action) => {
      state.latestError = null;
      state.errorType = null;
    })
    .addMatcher(isRejectedAction, (state, { payload, type }) => {
      if (payload?.message === 'Network Error') {
        // CUSTOMIZE AXIOS ERROR FOR LOCALE
        state.errorType = errorType.networkError;
        state.latestError =
          customErrors[
            httpClient.defaults.headers.common[
              'locale'
            ] as keyof typeof customErrors
          ].networkError;
        return;
      }
      LogsHandler.log(`payload: ${JSON.stringify(payload)},type: ${type}`);

      state.latestError = payload?.message;
      state.errorType = payload?.errorType;
      state[type.replaceAll('/rejected', '')] = payload?.message;
    });
});

export default errorReducer;
