import * as Yup from 'yup';
import { emailRegex, volumeRegex } from '@pharmaplan/common';
import strings from '../../localization';
import { nameRegex, onlyNumbersRegex, phoneRegex, postalCodeRegExp } from '../../helpers/Constants';

export const phoneValidation = () =>
  Yup.string()
    .max(
      12,
  strings.formatString(strings.phoneValidation, strings.mobile) as string,
    )
    .min(
      12,
  strings.formatString(strings.phoneValidation, strings.mobile) as string,
    )
    .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
    .nullable();

export const operationsValidation = () =>
  Yup.string()
    .required(strings.requiredField)
    .nullable()
    .matches(volumeRegex, strings.volumeValidation);

export const GeneralValidator = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.pleaseEnterValidName),
    lastName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.pleaseEnterValidName),
    preferdLanguage: Yup.string().required(strings.requiredField),
  });

export const ProInfoValidator = () =>
  Yup.object().shape({
    licenseNumber: Yup.string()
      .matches(
        onlyNumbersRegex,
        strings.formatString(
          strings.numberValidation,
          strings.licenseNo,
        ) as string,
      )
      .required(strings.requiredField),
    graduationYear: Yup.string()
      .matches(
        onlyNumbersRegex,
        strings.formatString(
          strings.numberValidation,
          strings.yearLicensed,
        ) as string,
      )
      .required(strings.requiredField),
    yearsOfExperience: Yup.string()
      .matches(
        onlyNumbersRegex,
        strings.formatString(
          strings.numberValidation,
          strings.yearsOfExperience,
        ) as string,
      )
      .required(strings.requiredField),
  });

export const SoftwareValidator = () =>
  Yup.object().shape({});

export const ContactValidator = () =>
  Yup.object().shape({
    address: Yup.string().required(strings.requiredField),
    city: Yup.string().required(strings.requiredField),
    province: Yup.string().max(2, strings.provinceValidation),
    postalCode: Yup.string()
      .required(strings.requiredField)
      .matches(postalCodeRegExp, strings.postalCodeValidation),
    homePhone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.homePhone,
        ) as string,
      ),
    mobile: Yup.string()
      .required(strings.requiredField)
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`),
  });

export const PrimaryValidator = () =>
  Yup.object().shape({
    primaryContactName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.pleaseEnterValidName),
    primaryContactPhone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.homePhone,
        ) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    primaryContactMobile: Yup.string()
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    primaryContactEmail: Yup.string()
      .matches(emailRegex, strings.emailValid)
      .required(strings.requiredField)
      .nullable(),
    communicationEmail: Yup.string()
      .matches(emailRegex, strings.emailValid)
      .nullable(),
  });

export const SecondaryValidator = () =>
  Yup.object().shape({
    secondaryContactName: Yup.string()
      .matches(nameRegex, strings.pleaseEnterValidName)
      .nullable(),
    secondaryContactPhone: Yup.string()
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    secondaryContactMobile: Yup.string()
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    secondaryContactEmail: Yup.string().matches(emailRegex, strings.emailValid).nullable(),
  });

export const CoordinatesValidator = () =>
  Yup.object().shape({
    address: Yup.string().required(strings.requiredField),
    city: Yup.string().required(strings.requiredField),
    province: Yup.string().max(2, strings.provinceValidation),
    postalCode: Yup.string()
      .required(strings.requiredField)
      .matches(postalCodeRegExp, strings.postalCodeValidation),
    emergencyPhone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.emergencyPhone,
        ) as string,
      ),
    phone: Yup.string()
      .required(strings.requiredField)
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    fax: Yup.string()
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.fax) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.fax) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
  });

export const PharmacyInfoValidator = () =>
  Yup.object().shape({
    name: Yup.string().required(strings.requiredField),
    weekVolume: operationsValidation(),
    weekendVolume: operationsValidation(),
    weekDayTechs: operationsValidation(),
    weekNightTechs: operationsValidation(),
    weekendDayTechs: operationsValidation(),
    weekendNightTechs: operationsValidation(),
    weekDayPharmacists: operationsValidation(),
    weekNightPharmacists: operationsValidation(),
    weekendDayPharmacists: operationsValidation(),
    weekendNightPharmacists: operationsValidation(),
    hourlyRate: Yup.number().when('isSelfService', {
      is: true,
      then: () =>
        Yup.number().typeError(
          strings.formatString(
            strings.numberValidation,
            strings.hourlyRate,
          ) as string,
        ),
    }),
  });

export const AccountingValidator = () =>
  Yup.object().shape({
    accountingContactName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.pleaseEnterValidName)
      .nullable(),
    accountingContactPhone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.homePhone,
        ) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    accountingContactMobile: Yup.string()
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    accountingContactEmail: Yup.string()
      .required(strings.requiredField)
      .matches(emailRegex, strings.emailValid)
      .nullable(),
  });
