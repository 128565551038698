import Routes from '../helpers/Routes';
import { encryptPassword } from '../helpers/utils';
import { ISmartRegisterParams } from '../models';
import { AppIDType } from '../models/IAppID';
import { ISystemDetailParams } from '../models/UtilModel';
import httpClient from './httpClient';

class UtilController {
  constructor() {}
  getContactInfo = async () => httpClient.get(`${Routes.util.getContactInfo}`);
  getLanguagesInfo = async () =>
    httpClient.get(
      `${Routes.authentication.authBasePath}${Routes.general.languages}`
    );
  getPublicKey = async (deviceId: string) =>
    httpClient.get(
      `${Routes.general.system}/${Routes.util.getPublicKey}/${deviceId}`
    );

  registerSmartLogin = async (params: ISmartRegisterParams) => {
    const encryptedTotp = encryptPassword(params.totp);
    return httpClient.post(
      `${Routes.general.system}/${Routes.util.registerSmartLogin}`,
      {
        registerSmartLoginModel: {
          ...params,
          totp: encryptedTotp,
        },
      }
    );
  };

  checkSystemDetails = async (params: ISystemDetailParams) =>
    httpClient.post(`${Routes.general.anonymous}${Routes.util.appDetails}`, {
      ...params,
      appid: `pp-${params.appid}`,
    });

  deregisterSmartLogin = async (params: { totp: string; userHash: string }) => {
    const encryptedTotp = encryptPassword(params.totp);
    const encryptedHash = encryptPassword(params.userHash);

    return httpClient.post(
      `${Routes.general.system}/${Routes.util.deRegisterSmartLogin}`,
      {
        userHash: encryptedHash,
        totp: encryptedTotp,
      }
    );
  };
}

export default new UtilController();
