/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Box } from '@mui/system';

import { IClientAdvertisement } from '@pharmaplan/common/models/Models';
import classes from '../style';

interface IAdsCarousel {
  ads: Array<IClientAdvertisement>;
  height: number;
}

const AdsCarousel: React.FC<IAdsCarousel> = ({ ads, height }) =>
  (
    <CarouselProvider
      naturalSlideWidth={250}
      naturalSlideHeight={height}
      totalSlides={ads?.length}
      interval={5000}
      isPlaying
    >
      <Slider>
        {ads?.map((item, index) => {
          const { navigateUrl, imageName, imageUrl } = item ?? {};
          const noUrl = !navigateUrl;
          const prependedHttpUrl = navigateUrl?.indexOf('://') === -1
            ? `http://${navigateUrl}`
            : navigateUrl;

          return (
            <Slide index={index}>
              <Box
                component="a"
                href={prependedHttpUrl ?? ''}
                target="_blank"
                sx={noUrl ? classes.disabled : {}}
              >
                <Box
                  component="img"
                  sx={[classes.adsContainerImg]}
                  src={imageUrl}
                  alt={imageName}
                />
              </Box>
            </Slide>
          );
        })}
      </Slider>
    </CarouselProvider>
  );

export default AdsCarousel;
