import { errorType, getErrorType, getLatestError } from '@pharmaplan/common';
import React, { useEffect } from 'react';
import OutcomeModal from '../components/Modals/OutcomeModal';
import { OutcomeModalTypes } from '../helpers/Constants';
import { setDialog } from '../reducers/dialogReducer';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

const useErrorHandler = () => {
  const error = useAppSelector(getLatestError);
  const errorCode = useAppSelector(getErrorType);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (window.navigator?.onLine && errorCode === errorType.networkError) return;

    error
      && dispatch(
        setDialog({
          showCloseButton: true,
          Component: (
            <OutcomeModal type={OutcomeModalTypes.error} message={error} />
          ),
          heading: {
            title: '',
          },
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};

export default useErrorHandler;
