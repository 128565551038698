import { useEffect } from 'react';

import { isLoggedIn } from '@pharmaplan/common/helpers/LogoutHandler';

const useLogoutCheck = () => {
  useEffect(() => {
    const handleInvalidToken = (e: StorageEvent) => {
      if (e.key === isLoggedIn) {
        window.location.reload();
      }
    };
    window.addEventListener('storage', handleInvalidToken);

    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
  }, []);
};

export default useLogoutCheck;
