import * as Yup from 'yup';
import { emailRegex } from '@pharmaplan/common';
import strings from '../../../localization';
import { operationsValidation } from '../../Profile/FormValidation';
import {
  nameRegex,
  phoneRegex,
  postalCodeRegExp,
} from '../../../helpers/Constants';

export const latitudeValidator = () =>
  Yup.number()
    .nullable()
    .when('isSelfService', {
      is: true,
      then: () =>
        Yup.number().nullable().required(strings.requiredField),
    });

export const pharmacyInfoValidator = () =>
  Yup.object().shape({
    name: Yup.string().required(strings.requiredField),
    number: Yup.string().trim().required(strings.requiredField),
    softwareId: Yup.string().required(strings.requiredField),
    languageId: Yup.string().required(strings.requiredField),
    pharmacyChainId: Yup.string().required(strings.requiredField),
    selectedContactPreferences: Yup.array().min(1, strings.pleaseSelectOne),
    weekVolume: operationsValidation(),
    weekendVolume: operationsValidation(),
    weekDayTechs: operationsValidation(),
    weekNightTechs: operationsValidation(),
    weekendDayTechs: operationsValidation(),
    weekendNightTechs: operationsValidation(),
    weekDayPharmacists: operationsValidation(),
    weekNightPharmacists: operationsValidation(),
    weekendDayPharmacists: operationsValidation(),
    weekendNightPharmacists: operationsValidation(),
  });

export const coordinateValidator = () =>
  Yup.object().shape({
    address: Yup.string().required(strings.requiredField),
    city: Yup.string().required(strings.requiredField),
    province: Yup.string().required(strings.requiredField),
    postalCode: Yup.string()
      .required(strings.requiredField)
      .matches(postalCodeRegExp, strings.postalCodeValidation),
    emergencyPhone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.emergencyPhone,
        ) as string,
      ),
    phone: Yup.string()
      .required(strings.requiredField)
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`),
    fax: Yup.string()
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.fax) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.fax) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`),
    latitude: latitudeValidator(),
  });

export const primaryValidator = () =>
  Yup.object().shape({
    primaryContactName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.pleaseEnterValidName),
    primaryContactPhone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.homePhone,
        ) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`),
    primaryContactEmail: Yup.string()
      .matches(emailRegex, strings.emailValid)
      .required(strings.requiredField),
    communicationEmail: Yup.string()
      .matches(emailRegex, strings.emailValid)
      .nullable(),
  });

export const secondaryValidator = () =>
  Yup.object().shape({
    secondaryContactName: Yup.string().matches(nameRegex, strings.pleaseEnterValidName),
    secondaryContactPhone: Yup.string()
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.accountingContactPhone,
        ) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    secondaryContactEmail: Yup.string()
      .matches(emailRegex, strings.emailValid)
      .nullable(),
  });

export const accountingValidator = () =>
  Yup.object().shape({
    accountingContactName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.pleaseEnterValidName),
    accountingContactPhone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.accountingContactPhone,
        ) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`),
    accountingContactEmail: Yup.string()
      .matches(emailRegex, strings.emailValid)
      .required(strings.requiredField),
  });
